import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class PayingGuard  {

    constructor(
        private router: Router,
        private authService: AuthService,
        private toastrService: ToastrService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (localStorage.getItem('subscriber') !== 'ACTIVE') {
            this.toastrService.info('Recurso exclusivo para assinantes!');
            this.router.navigate(['/payments/sign']);
            return false;
        }

        return true;
    }
}
